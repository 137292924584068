import { getAccoutKey } from './Access';
import { LocalStorage } from '@/core/Storage';
import type { PermissionsType } from './PermissionTypes';
import type { ClientSettings } from './SettingsTypes';

export function getAccoutData() {
  return LocalStorage.getItem<{
    permissions: {
      permissions: PermissionsType;
      settings: ClientSettings;
    };
  }>(getAccoutKey('account'));
}
