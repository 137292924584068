import { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryPrams } from '@/view/hooks/useQueryParams';
import { ApolloError, ApolloQueryResult, NetworkStatus, QueryOptions } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { decodeQueryVariables } from '@/libs/encodedQuery';
import { promiseDebounce } from '@/view/utils/promiseDebounce';
import omitBy from 'lodash/omitBy';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import range from 'lodash/range';

/*
 * this tenpalte is being used only inside codegen to work with infinity data
 */

export type LoadMoreFn<T = any> = ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => Promise<T>;
type OptionsType = Omit<QueryOptions, 'query'>;

function isSuccess(obj?: { [key: string]: any }) {
  return Object.values(obj?.data || {}).findIndex((data: any) => data?.success) > -1;
}

export type InfinityConfig<Filters, Responce, UpdateResponce, DataType> = {
  useUrlQuery?: boolean;
  transformValue: (resp?: Responce) => any;
  filters?: Partial<Filters>;
  controller?: AbortController;
  onLoad?: (data?: Responce) => void;
  listGetter?: string;
  onUpdate?: (state: any, updateResponce: UpdateResponce) => DataType;
  fetchOnMount?: boolean;
  parseUrlQuery?: (filters: any) => Filters;
};

function defaultQueruURLParser<T>(query: T) {
  return query;
}

export function useInfinityDataTemplate<
  GetResponce,
  RemoveResponce,
  UpdateResponce,
  CreateResponce,
  GetVariables,
  RemoveVariables,
  UpdateVariables,
  CreateVariables,
  DataType,
  Filters = GetVariables
>(
  {
    list,
    remove,
    update,
    create
  }: {
    list: (variables: GetVariables, options?: OptionsType) => Promise<ApolloQueryResult<GetResponce>>;
    remove?: (variables: RemoveVariables, options?: OptionsType) => Promise<ApolloQueryResult<RemoveResponce>>;
    update?: (variables: UpdateVariables, options?: OptionsType) => Promise<ApolloQueryResult<UpdateResponce>>;
    create?: (variables: CreateVariables, options?: OptionsType) => Promise<ApolloQueryResult<CreateResponce>>;
  },
  config?: InfinityConfig<GetVariables, GetResponce, UpdateResponce, DataType>
) {
  const controller = useRef<AbortController>(config?.controller || new AbortController());
  const [_, setQueryParams] = useQueryPrams();
  let { useUrlQuery = true, transformValue } = config || {};
  const parseFilters = config?.parseUrlQuery || defaultQueruURLParser;
  const [data, setData] = useState<{
    loading: boolean;
    data?: DataType | null;
    filters: Partial<Filters>;
    error?: ApolloError;
    errors?: readonly GraphQLError[];
  }>({ loading: true, data: null, filters: useUrlQuery ? decodeQueryVariables<GetVariables>() : {} });

  useEffect(() => {
    const { fetchOnMount = true } = config || {};
    if (!fetchOnMount) return;
    controller.current.abort();
    controller.current = new AbortController();
    let filters = (useUrlQuery ? decodeQueryVariables<GetVariables>() : config?.filters || {}) as GetVariables;
    filters = { ...filters, ...config?.filters };
    list(parseFilters(filters), {
      context: {
        fetchOptions: { signal: controller.current.signal },
        queryDeduplication: false
      }
    })
      .then(resp => {
        setData(state => ({
          ...state,
          loading: false,
          filters,
          data: resp.networkStatus === NetworkStatus.ready ? (transformValue ? transformValue(resp.data) : resp.data) : null,
          error: resp.error,
          errors: resp.errors
        }));
        config?.onLoad && config?.onLoad(resp?.data);
      })
      .catch(err => {
        if (err?.networkError?.code !== 20) {
          return Promise.reject(err);
        }
      });

    return () => controller.current.abort();
  }, []);

  const clear = useCallback(() => {
    setData({
      loading: false,
      data: null,
      filters: {},
      error: undefined,
      errors: undefined
    });
  }, []);
  const filter = useCallback(
    promiseDebounce((filters: Partial<GetVariables> = {}, fetchConfigs?: { hideLoading?: boolean }) => {
      controller.current.abort();
      controller.current = new AbortController();
      const _filters = omitBy({ ...config?.filters, ...data.filters, ...filters }, val => val == null) as any as GetVariables;
      setData(state => ({ ...state, loading: !fetchConfigs?.hideLoading, filters: _filters }));

      if (useUrlQuery) {
        setQueryParams({ ..._filters });
      }

      return list(parseFilters(_filters), {
        context: { fetchOptions: { signal: controller.current.signal }, queryDeduplication: false }
      }).then(resp => {
        setData(state => ({
          ...state,
          loading: false,
          filters: _filters,
          data: resp.networkStatus === NetworkStatus.ready ? (transformValue ? transformValue(resp.data) : resp.data) : null,
          error: resp.error,
          errors: resp.errors
        }));
        return resp;
      });
    }, 200),
    [data.filters]
  );

  const loadMore = useCallback(
    promiseDebounce(({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
      const limit = stopIndex - startIndex + 1;
      const dataKey = `data.${config?.listGetter || 'values'}`;
      setData(state => {
        const new_state = cloneDeep({ ...state });
        const arr = [...get(new_state, dataKey, [])];
        range(limit).forEach(index => {
          //@ts-ignore
          arr[(index as number) + startIndex] = {};
        });
        set(new_state, dataKey, arr);
        return new_state;
      });

      const filters = { ...data.filters, limit, skip: startIndex } as any as GetVariables;
      return list(parseFilters(filters), {
        context: { fetchOptions: { signal: controller.current.signal }, queryDeduplication: false }
      })
        .then(resp => {
          const data =
            resp.networkStatus === NetworkStatus.ready ? (transformValue ? transformValue(resp.data) : resp.data) : null;
          const respArray = get(data, config?.listGetter || 'values');
          if (!isEmpty(respArray)) {
            setData(state => {
              const new_state = cloneDeep(state);
              const arr = [...get(new_state, `data.${config?.listGetter || 'values'}`, [])];
              respArray.forEach((item: any, index: number) => {
                arr[index + startIndex] = item;
              });
              set(new_state, dataKey, arr);
              return new_state;
            });
          }
          return resp;
        })
        .catch(err => {
          if (err?.networkError?.code !== 20) {
            return Promise.reject(err);
          }
        });
    }, 200),
    [data.filters]
  );
  const _update = useCallback(
    (variables: UpdateVariables) => {
      if (!update) return Promise.resolve({} as ApolloQueryResult<UpdateResponce>);
      return update(variables, {
        context: { fetchOptions: { signal: controller.current.signal }, queryDeduplication: false }
      }).then((resp: ApolloQueryResult<UpdateResponce>) => {
        if (isSuccess(resp)) {
          if (config?.onUpdate) {
            const cb = config?.onUpdate;
            setData(state => ({
              ...state,
              data: cb(state.data, resp.data)
            }));
            return resp;
          } else {
            filter({});
          }
        }
        return resp;
      });
    },
    [filter]
  );

  const _remove = useCallback(
    (variables: RemoveVariables) => {
      if (!remove) return Promise.resolve({} as ApolloQueryResult<RemoveResponce>);
      return remove(variables, {
        context: { fetchOptions: { signal: controller.current.signal }, queryDeduplication: false }
      }).then((resp: ApolloQueryResult<RemoveResponce>) => {
        if (isSuccess(resp)) {
          filter({});
        }
        return resp;
      });
    },
    [filter]
  );

  const _create = useCallback(
    (variables: CreateVariables, onCreate?: (resp: ApolloQueryResult<CreateResponce>) => any) => {
      if (!create) return Promise.resolve({} as ApolloQueryResult<CreateResponce>);
      return create(variables, {
        context: { fetchOptions: { signal: controller.current.signal }, queryDeduplication: false }
      }).then((resp: ApolloQueryResult<CreateResponce>) => {
        if (isSuccess(resp)) {
          return onCreate ? Promise.resolve(onCreate(resp)).then(() => filter({}).then(() => resp)) : filter({}).then(() => resp);
        }
        return resp;
      });
    },
    [create, filter]
  );

  const _setData = useCallback((callback: (data: DataType | null | undefined) => DataType | null | undefined) => {
    setData(state => ({ ...state, data: callback(state.data) }));
  }, []);

  return { ...data, filter, remove: _remove, create: _create, update: _update, setData: _setData, loadMore, clear };
}
