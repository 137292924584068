import { ToastContent, toast } from 'react-toastify';
import { getAccoutData } from '@/shared/access/getAccoutData';
import { gettext } from '@cranium/i18n';
import { throttle } from 'lodash';

let throttledToast: (((content: string | ToastContent) => void) & { cancel: () => void }) | undefined;

const getThrottledToast = (delay: number) => {
  if (!throttledToast) {
    throttledToast = throttle(
      (content: string | ToastContent) => {
        toast(content, {
          position: 'top-right',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          bodyClassName: 'pr-1',
          type: 'error'
        });
      },
      delay * 1000,
      { leading: true, trailing: false }
    );
  }

  return throttledToast;
};

export const showToastify = (content: string | ToastContent, extensions_code: string) => {
  let _content: string | ToastContent = gettext(
    'Something went wrong, please refresh the page and if the error persists, contact support'
  );
  const accountData = getAccoutData();
  const prettyMessage = accountData?.permissions?.settings?.errors?.pretty_message;
  const showAsItIs = accountData?.permissions?.settings?.errors?.show_as_it_is_codes?.includes(extensions_code);
  const coolDown = accountData?.permissions?.settings?.errors?.message_cool_down_sec || 0;

  if (!prettyMessage || showAsItIs) {
    _content = content;
  }

  const throttledToast = getThrottledToast(coolDown);
  throttledToast(_content);
};
