import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { SelectProps } from 'antd';
import { IncludedPrimaryTypes, LocationBounds, PlacePrediction } from './types';
import { Address } from '@/gql/types.generated';
import { buildInputQuery } from '@/shared/components/dataEntry/LocationInput/utils';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { PlaceAddressComponent } from '@shared-core/google/lib/PlaceAddressComponent';

type LocationInputProps = {
  countryCode?: string | undefined;
  value?: Partial<Address | null> | string;
  includedPrimaryTypes?: IncludedPrimaryTypes[] | ['(cities)'] | ['(regions)'];
  locationBounds?: LocationBounds;
  formatValue?: (address: Partial<Address> | null | undefined) => string;
  formatOptionLabel?: (placeAddressComponent: PlaceAddressComponent) => string;
  onChange?: (address: Partial<Address> | undefined) => void;
  onSelect?: (address: Partial<Address>) => void;
  onClear?: () => void;
  onBlur?: (ev?: any) => void;
} & Omit<SelectProps<ValueType | string, OptionType>, 'value' | 'onSelect' | 'onChange' | 'onFocus' | 'onBlur' | 'onDeselect'>;

type OptionType = {
  label: ReactNode;
  value: string;
  disabled?: boolean;
  data: PlacePrediction;
};

type ValueType = {
  label: ReactNode;
  value: string;
};

function formatPlace(place?: google.maps.places.PlaceResult): Partial<Address> | undefined {
  if (place?.address_components) {
    const map = place.address_components.reduce((res, item) => {
      item.types.forEach(type => {
        res.set(type, item);
      });
      return res;
    }, new Map<string, google.maps.GeocoderAddressComponent>());
    const display = place.formatted_address?.replace(/,/, '\x01').split('\x01');
    return {
      city: map.has('locality') ? map.get('locality')?.long_name : undefined,
      country: map.has('country') ? map.get('country')?.long_name : undefined,
      display: display,
      id: place.place_id,
      location: {
        latitude: place.geometry?.location?.lat() as number,
        longitude: place.geometry?.location?.lng() as number
      },
      mainText: display ? display[0] : place.formatted_address || '',
      secondaryText: display ? display[1] : '',
      state: map.has('administrative_area_level_1') ? map.get('administrative_area_level_1')?.short_name : undefined,
      zip: map.has('postal_code') ? map.get('postal_code')?.long_name : undefined
    };
  }
  return undefined;
}

export const LocationInput: React.FC<LocationInputProps> = ({
  countryCode,
  locationBounds,
  value,
  onChange,
  onSelect,
  onClear,
  id,
  className,
  ...props
}) => {
  const propsRef = useRef({ onChange });
  Object.assign(propsRef.current, { onChange });
  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const places = useMapsLibrary('places');

  const _val = useMemo(() => {
    if (value && typeof value === 'object') {
      return buildInputQuery(value);
    }
    return value;
  }, [value]);
  useEffect(() => {
    if (!places || !inputRef.current) return;
    const options = {
      fields: ['geometry', 'name', 'formatted_address', 'place_id', 'address_components']
    };
    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places, _val]);
  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      if (!placeAutocomplete) return;
      const place = placeAutocomplete?.getPlace();
      if (propsRef.current?.onChange) {
        propsRef.current.onChange(formatPlace(place));
      }
    });
  }, [placeAutocomplete]);

  return (
    <input
      id={id}
      {...(props as any)}
      className={`ant-input ant-input-status-success ${className}`}
      ref={inputRef}
      onBlur={ev => {
        props?.onBlur && props?.onBlur(ev);
        if (!ev.target.value && propsRef?.current?.onChange) propsRef.current.onChange(undefined);
      }}
      defaultValue={_val || ''}
      key={_val}
    />
  );
};
