import { showToastify } from './showToastify';

import configuration from '@/core/Configuration';
import { onError } from '@apollo/client/link/error';
import { LocalStorage } from '@/core/Storage';

const initErrorLink = (stopWatch: () => void) => {
  return onError(data => {
    const { graphQLErrors, operation } = data;
    const context = operation.getContext();
    const requestId = context?.response?.headers?.get('X-Request-Id');
    if (graphQLErrors) {
      const hasToken = context?.response?.headers && context?.response?.headers.get('authorization');
      const hasUnauthorizedError = !!graphQLErrors.find(
        ({ message }) => message === 'Unauthorized access' || message.includes('Unauthorized')
      );
      if (hasUnauthorizedError && hasToken) {
        //@ts-ignore
        data.response.errors = null;
        return;
      }
    }

    if (graphQLErrors)
      graphQLErrors.forEach(({ message, extensions }) => {
        // We don't have error codes for Unauthorized error
        // That's why we are trying to detect Unauthorized request by error message
        // In case if message is changed it will stop working
        if (message === 'Unauthorized access' || message.includes('Unauthorized')) {
          const prevLocation =
            LocalStorage.getItem<string>('location.beforeLogin') ||
            [window.location.pathname, window.location.search].filter(Boolean).join('');
          LocalStorage.clearAll();
          if (prevLocation && !prevLocation.includes('login')) {
            LocalStorage.setItem('location.beforeLogin', prevLocation);
          }
          stopWatch();
        } else {
          if (configuration.toastErrors) {
            showToastify(`${message}`, extensions?.code);
          }
        }
      });
  });
};

export { initErrorLink };
