import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminRouter from './AdminRouter';
import { PrivateRoute } from '@/shared/access';
import { PartnerRoute } from '@/shared/navigation';
const QuestionaryTestPage = React.lazy(() =>
  import('@pages/QuestionaryTest').then(module => ({ default: module.QuestionaryTestPage }))
);
const Login = React.lazy(() => import('@pages/Login').then(module => ({ default: module.Login })));

export default function ApplicationRouter() {
  return (
    <Switch>
      <Route
        path="/login/:identity?"
        component={(props: any) => (
          <Suspense fallback={null}>
            <Login {...props} />
          </Suspense>
        )}
        key="/login"
      />
      <PartnerRoute
        path="/(p-)?:partnerId?/questionary-test-page"
        component={() => (
          <Suspense fallback={null}>
            <QuestionaryTestPage />
          </Suspense>
        )}
        key="/questionary"
      />
      <PrivateRoute path="/" component={AdminRouter} />
    </Switch>
  );
}
